@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.addressContainer {
    align-self: center;
    font-family: $font-stack-all;
    font-size: 14px;
    width: 100%;
}

.buttonAlign {
    align-self: flex-end;
    margin-left: auto;
}

.buttonContainer {
    align-content: flex-end;
    align-items: center;
    border-top: 1px solid $nobel;
    display: flex;
    margin-top: 30px;
    padding-top: 10px;
}

.continueButton {
    cursor: pointer;
    font-size: 14px;
    height: 32px;
    line-height: 1.43;
    width: 125px;

    &:focus::after {
        border-color: $white ;
        border-width: 1.5px;
        border-style: solid;
    }
}

.fieldContainer {
    width: inherit;
}

.headerContainer {
    color: $black;
    margin-bottom: 30px;
}

.message {
    font-size: 11px;
}

.popup {
    max-width: 700px;
    min-height: 350px;
    min-width: 600px;
}

.spinnerStyle {
    align-self: flex-end;
    margin: 4px;
}

.fieldIconsDisabled {
    color: rgb(161, 159, 157);
    pointer-events: none;
}

.problemDescLabel {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
}

.problemDescLabelDisabled {
    color: rgb(161, 159, 157);
}

.problemDescIcon {
    vertical-align: middle;
    margin-left: 4px;
    font-size: 1rem;
    color: rgb(50, 49, 48);
}

.problemDescRequired {
    color: rgb(164, 38, 44);
}

.fieldName {
    vertical-align: middle;
}

.fieldIconsDisabled {
    color: rgb(161, 159, 157);
    pointer-events: none;
}